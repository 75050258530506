import React, { useState, useEffect } from "react"; 
import { Table, Button } from "antd"; 
import { columnsData } from "./columnsData"; 
import { getColumnSearchProps, EditableFormRow, EditableCell } from "common"; 
import { IgtLoader } from 'Atoms' 
import { CSVLink } from "react-csv"; 
 

export const CrudTable = ({ isLoading, modelList }) => { 

  const [data, setData] = useState(null) 
  const [, setSearchText] = useState("") 
  const [, setSearchedColumn] = useState("") 

  useEffect(() => { 

    console.log(modelList) 
    setData(modelList) 
  }, [isLoading, modelList, data]) 
 
  const handleSearch = (selectedKeys, confirm, dataIndex) => { 
    confirm();
    setSearchText(selectedKeys[0]) 
    setSearchedColumn(dataIndex) 
  }; 

  const handleReset = (clearFilters) => { 
    clearFilters(); 
    setSearchText({ searchText: "" }) 
  }; 

 
 

  const defaultColumns = [ 
    ...columnsData.map(column => { 
      return column.isSearchable ? { 
        ...column, 
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset) 
      } : column 

    }) 

  ].map(col => { 
    if (!col.editable) { 
      return col; 
    } 

    return { 
      ...col, 
      sorter: (rowA, rowB) => { 
        switch (col.dataIndex) { 
          case "business_units_id": 
            return col.sorter(rowA['business_unit_name'], rowB['business_unit_name']); 
          case "default_cost_center_id": 
            return col.sorter(rowA['cost_center'], rowB['cost_center']); 
          default: 
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]); 
        } 
      } 
    }; 
  }); 

 
 

  const CSVDataToDownloadFormat = (data) => { 
    return data.map(githubCopilotUsers => { 
      return { 
        cost_center: githubCopilotUsers.cost_center, 
        github_organization: githubCopilotUsers.github_organization, 
        cloud_contact: githubCopilotUsers.cloud_contact, 
        github_username: githubCopilotUsers.github_username, 
        easy_vista_ticket_number: githubCopilotUsers.easy_vista_ticket_number, 
        requestor: githubCopilotUsers.requestor 

      } 
    }) 
  } 
 
  return ( 
    <> 
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<> 
      <Button style={{ margin: '3px' }} > 
            {modelList && ( 
              <CSVLink  
                data={CSVDataToDownloadFormat(data)}  
                filename={'githubcopilotusers_' + new Date().toISOString() + '.csv'} 
              > 
                Download CSV 
              </CSVLink> 
            )} 

          </Button> 
      <Table 
        title={() => 'Github Copilot Users Table'}  
          pagination={{ position: 'both' }}  // pagination={false}  
          rowKey="id" 
          size='middle' 
          components={{ 
            body: { 
              row: EditableFormRow, 
              cell: EditableCell 
            } 
          }} 
          bordered 
          dataSource={data} 
          columns={defaultColumns} 
          rowClassName="editable-row" 
        /> 
      </> 
      )} 
    </> 
  ); 
} 