import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { columnsData } from "./columnsData";
import { getColumnSearchProps, EditableFormRow, EditableCell } from "common";
import { IgtLoader } from 'Atoms'

export const CrudTable = ({ isLoading, modelList }) => {
  const [data, setData] = useState(null)
  const [, setSearchText] = useState("")
  const [, setSearchedColumn] = useState("")

  useEffect(() => {

    console.log(modelList)
    setData(modelList)
  }, [isLoading, modelList, data])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText({ searchText: "" })
  };

  const defaultColumns = [
    ...columnsData.map(column => {
      return column.isSearchable ? {
        ...column,
        ...getColumnSearchProps(column.dataIndex, handleSearch, handleReset)
      } : column
    })
  ].map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      sorter: (rowA, rowB) => {
        switch (col.dataIndex) {
          case "created_at":
            return "date";
          case "updated_at":
            return "date";
          default:
            return col.sorter(rowA[col.dataIndex], rowB[col.dataIndex]);
        }
      }
    };
  });

  return (
    <>
      {isLoading ? <IgtLoader isLoading={isLoading} /> : (<>
      <Table
        title={() => 'Microsoft Approvals Table'} 
          pagination={false}  // pagination={false} 
          rowKey="id"
          size='middle'
          components={{
            body: {
              row: EditableFormRow,
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={defaultColumns}
          rowClassName="editable-row"
        />
      </>
      )}
    </>
  );
}