import React from 'react'
import { Header } from 'Organisms'
import {
  Container,
  Col,
  Row
} from 'reactstrap'
import {
  Switch,
  PrivateRoute,
  PublicRoute
} from 'Atoms'
import {
  Redirect
} from 'react-router-dom'
import {
  PageNotFound,
  Unauth,
  Auth
} from 'Ecosystems'
import * as Models from 'Models'


const App = (props) => {

  const { isLoggedIn } = props
  const snakeCaseModels = Object.keys(Models).map(model => model.split(/(?=[A-Z])/).join('_').toLowerCase())

  const newProps = {...props}
  newProps.snakeCaseModels = snakeCaseModels
  
  return (
    <Container fluid>
      <Row>
        <Col lg="12">
          <Header 
            isLoggedIn={isLoggedIn}
          />
        </Col>
      </Row>
      <Row className="main-content">
        <Col lg="12">
          <Switch>
            <PublicRoute
              exact
              path="/unauth/:action(home|login|logout)/"
              props={props}
              component={Unauth}
            />
            <PrivateRoute
              path="/auth/:action(home|create-aws-account|create-azure-subscription|create-gcp-project|dash-stats|membership-requests|active-directory|billing-automation|account-details|missing-tags-report|customers)"
              props={props}
              component={Auth}
            />
            <PrivateRoute
              path={`/auth/:action(view|edit|create|crud)/:model(${snakeCaseModels.join('|')})/:modelId?`}
              props={newProps}
              component={Auth}
            />
            <PublicRoute
              path={'/:incorrect_route'}
              props={props}
              component={PageNotFound}
            />
            <Redirect from="/" to="/unauth/home" />
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}

export default App
