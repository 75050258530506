import React, { useState, useEffect } from 'react'
import { storage, loginApi } from 'lib'
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Card, 
  CardBody, 
  CardTitle, 
  CardText, 
  CardFooter, 
  CardLink
} from 'reactstrap'

const CardItem = ({obj}) => {
  const [modal, setModal] = useState(false);
  const toggleInventoryModal = () => setModal(!modal);
  const [otherModal, setOtherModal] = useState(false);
  const toggleOtherModal = () => setOtherModal(!otherModal);
  const user_email = storage.get('userEmail');
  const [email, setEmail] = useState(user_email) // who do we send the report to

  useEffect(() => {
    setEmail(storage.get('userEmail'))
  }, [])

  const onSendReport = () => {
    let content = {
      email: false,
      send_to: email
    }
    // let data = obj.modalType === "inventory" ? { cost_center: content } : { cloud_bill: content }
    let data = {}
    if (obj.modalType === "inventory") {
      data = { cost_center: content }
    } else if (obj.modalType === "bill") {
      data = { cloud_bill: content }
    } else if (obj.modalType === "database") {
      data = { db_dump: content }
    } else if (obj.modalType === "terminatedaccounts") {
      data = { cloud_contact: {to_address: email} }
    } else if (obj.modalType === "other") {
      data = {}
    }
    
    console.log("Submitted")
    console.log(email)
    console.log(obj.buttonUrl, data)
    console.log(sessionStorage.getItem('authHeaders'))
    loginApi().post(obj.buttonUrl, data)
      .then(resp => {
        alert("Your report will be emailed!");
      })
      .catch(err => {
        alert("There was a problem with this action. Please try again later: ", err)
        console.error("API Error: ", err)
      })
    toggleInventoryModal()
  }

  function generateModal(modal, toggle, onSubmit, setEmail, email, type) {
    let description_content = 'Would you like to recieve a new copy of the AWS Zipped Bill?'
    let title = 'Email Excel Report'
    let action = 'Send Report'
    if (type === 'inventory') {
      description_content = 'Would you like to generate a new copy of `CCOE-Cloud-Inventory.xlsx`?'
    } else if (type === 'database') {
      description_content = 'Would you like to generate a new Database Backup for Local Development?'
      title = 'Email Backup File'
      action = 'Send File'
    }
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {description_content}
          <InputGroup>
            <InputGroupText>Email</InputGroupText>
            <Input value={email} onChange={event => setEmail(event.target.value)} />
          </InputGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>{action}</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  function generateOtherModal(modal, toggle, onSubmit, id, action) {
    let title = 'Are you sure?'
    let description_content = ''
    if (id === 'expiredCloudParentCostCenters') {
      description_content = 'This function will send emails to all cloud accounts that are tagged with expired cost centers, click submit below if you wish to proceed.'
    } else if (id === 'terminatedEmailToCloudAccounts') {
      description_content = 'This function will send emails to all cloud account DLs that are owned by a terminated user. Click submit below if you wish to proceed.'
    }
    return (
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          {description_content}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>{action}</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Card >
      <CardBody style={{ backgroundColor: obj.color, display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}>
          <Row>
              <Row>
                  <Col>
                      <CardText style={{
                          textAlign: 'right',
                          fontSize: '200%'
                      }}>{obj.cardStat}</CardText>
                      <CardTitle style={{ display:'flex', justifyContent: 'center', color: '#fff', width: "24rem" }}>{obj.cardTitle}</CardTitle>
                  </Col>
              </Row>
          </Row>
      </CardBody>
      <CardFooter style={{ display: 'flex', justifyContent: 'center' }}>
        { obj.buttonType === 'modal' && (
          <>
            <Button onClick={toggleInventoryModal}>{obj.buttonTitle}</Button>
            {generateModal(modal, toggleInventoryModal, onSendReport, setEmail, email, obj.modalType)}
          </>
        )}
        { obj.buttonType === 'href' && (
          <CardLink href={obj.buttonUrl}><Button /* style={{ color: obj.color }} */>{obj.buttonTitle}</Button></CardLink>
        )}

      { obj.buttonType === 'other' && (
                <>
        <Button onClick={toggleOtherModal}>{obj.buttonTitle}</Button>
        {generateOtherModal(otherModal, toggleOtherModal, onSendReport, obj.id, obj.buttonTitle)}
        </>
      )}
      </CardFooter>
    </Card>
  )
}

export default CardItem

