import React from "react" 
import {Sorter} from "lib" 
// Note(Dennis): Set the editable to true as 
// Because sort feature doesn't work if editable set to false 

export const columnsData = [ 
  { 
    title: "Cost Center", 
    dataIndex: "default_cost_center_id", 
    sorter: Sorter.TEXT, 
    width: "7%", 
    render: (text, record) => { 
      return <>{record["cost_center"]}</> 
    }, 
    editable: true, 
    isSearchable: true 
  }, 
  { 
    title: "Github Organization", 
    dataIndex: "github_organization", 
    width: "7%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
    }, 
  { 
    title: "Email", 
    dataIndex: "cloud_contact", 
    width: "8%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
  }, 
  { 
    title: "Github Username", 
    dataIndex: "github_username", 
    width: "7%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
  }, 
  { 
    title: "Easy Vista Ticket Number", 
    dataIndex: "easy_vista_ticket_number", 
    width: "7%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
  }, 
  { 
    title: "Requestor", 
    dataIndex: "requestor", 
    width: "7%", 
    editable: true, 
    sorter: Sorter.TEXT, 
    isSearchable: true 
  } 
]; 